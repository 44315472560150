<template>
    <div class="home">
        <img src="@/assets/img/home.png" alt="">
    </div>
</template>
  
<script>
export default {
    name: 'homeIndex',
    data() {
        return {}
    },
    mounted() { },
    methods: {}
}
</script>
<style lang="scss" scoped>
.home {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        //   
        border-radius: 4px;
    }
}
</style>
  